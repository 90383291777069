<template>
    <!-- Stats Horizontal Card -->
    <div class="row">
        <div class="col-lg-3 col-sm-6 col-12">
            <div class="card">
                <div class="card-header">
                    <div>
                        <h2 class="fw-bolder mb-0">{{ totalRecruitment }}</h2>
                        <p class="card-text">Total Recruitment</p>
                    </div>
                    <div class="avatar bg-light-primary p-50 m-0">
                        <div class="avatar-content">
                            <i class="bi bi-briefcase bs-icon-normal" ></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-sm-6 col-12">
            <div class="card">
                <div class="card-header">
                    <div>
                        <h2 class="fw-bolder mb-0">
                             {{ completedRecruitment }}
                             <span class="fs-6 fw-light">
                               {{ statPercentage(completedRecruitment) }}
                             </span>
                        </h2>
                        <p class="card-text">Completed</p>
                    </div>
                    <div class="avatar bg-light-success p-50 m-0">
                        <div class="avatar-content ">
                            <i class="bi bi-star-fill bs-icon-normal" ></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-sm-6 col-12">
            <div class="card">
                <div class="card-header">
                    <div>
                        <h2 class="fw-bolder mb-0">
                            {{ ongoingRecruitment }}
                            <span class="fs-6 fw-light">
                              {{ statPercentage(ongoingRecruitment) }}
                            </span>
                        </h2>
                        <p class="card-text">Ongoing</p>
                    </div>
                    <div class="avatar bg-light-primary p-50 m-0">
                        <div class="avatar-content">
                            <i class="bi bi-speedometer bs-icon-normal" ></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-3 col-sm-6 col-12">
            <div class="card">
                <div class="card-header">
                    <div>
                        <h2 class="fw-bolder mb-0">
                            {{ draftedRecruitment }}
                           <span class="fs-6 fw-light">
                               {{ statPercentage(draftedRecruitment) }}
                           </span>
                        </h2>
                        <p class="card-text">Drafted</p>
                    </div>
                    <div class="avatar bg-light-primary p-50 m-0">
                        <div class="avatar-content">
                            <i class="bi bi-folder-fill bs-icon-normal" ></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--/ Stats Horizontal Card -->
</template>

<script>

// import { computed } from 'vue'

export default{
    name: 'ReportsRecruitmentStatsTiles',
    props:{
        totalRecruitment:{
            type:Number,
            default:0
        },
        completedRecruitment:{
            type:Number,
            default:0
        },
        ongoingRecruitment:{
            type:Number,
            default:0
        },
        draftedRecruitment:{
            type:Number,
            default:0
        }
    },
    setup(props){

        function statPercentage(stat){
            let percentage = Math.round(stat/props.totalRecruitment * 100);
            return stat > 0 ? `(${percentage}%)` : '';
        }

        return {
            statPercentage
        }
    }

}
</script>

<template>

    <!-- BEGIN: Content-->
    <div class="app-content content ">
        <div class="content-overlay"></div>
        <div class="header-navbar-shadow"></div>
        <div class="content-wrapper container-xxl p-0">
            <div class="content-header row">
            </div>
            <div class="content-body">
                <!-- Dashboard Ecommerce Starts -->
                <section id="dashboard-ecommerce">
                    <div class="row match-height">
                        <!-- Statistics Card -->
                        <div class="col-xl-12 col-md-12 col-12 mt-3" >
                            <div class="card card-statistics">
                                <div class="card-header">
                                     <h4 class="card-title">
                                            The
                                            <span class="text-primary">
                                                {{ userName }}
                                            </span>
                                            dashboard today,
                                            <span class="date-font"> {{ currentDate }} </span>
                                      </h4>
                                    <div class="d-flex align-items-center">
                                        <p class="card-text font-small-2 me-25 mb-0">
                                            <router-link to="/recruitment_post_job_ad" >
                                                <button type="button" class="btn btn-primary">Post New Job Ad</button>
                                            </router-link>
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <!--/ Statistics Card -->
                    </div>

                    <reports-recruitment-stats-tiles
                        :totalRecruitment="keyRecruitmentStats.total"
                        :completedRecruitment="keyRecruitmentStats.completed"
                        :ongoingRecruitment="keyRecruitmentStats.ongoing"
                        :draftedRecruitment="keyRecruitmentStats.drafted"
                    >
                    </reports-recruitment-stats-tiles>


                    <div class="row match-height">
                        <!-- Company Table Card -->
                        <div class="col-lg-8 col-12" v-if="false">
                            <reports-notification-list>
                            </reports-notification-list>
                        </div>
                        <!--/ Company Table Card -->

                        <!-- Developer Meetup Card -->
                        <div class="col-lg-4 col-md-6 col-12"  v-if="false">
                            <div class="card card-developer-meetup">
                                <div class="meetup-img-wrapper rounded-top text-center">
                                    <img src="../../assets/images/illustration/email.svg" alt="Meeting Pic" height="170" />
                                </div>
                                <div class="card-body">
                                    <div class="meetup-header d-flex align-items-center">
                                        <div class="meetup-day">
                                            <h6 class="mb-0">THU</h6>
                                            <h3 class="mb-0">24</h3>
                                        </div>
                                        <div class="my-auto">
                                            <h4 class="card-title mb-25">Developer Meetup</h4>
                                            <p class="card-text mb-0">Meet world popular developers</p>
                                        </div>
                                    </div>
                                    <div class="mt-0">
                                        <div class="avatar float-start bg-light-primary rounded me-1">
                                            <div class="avatar-content">
                                                <i data-feather="calendar" class="avatar-icon font-medium-3"></i>
                                            </div>
                                        </div>
                                        <div class="more-info">
                                            <h6 class="mb-0">Sat, May 25, 2020</h6>
                                            <small>10:AM to 6:PM</small>
                                        </div>
                                    </div>
                                    <div class="mt-2">
                                        <div class="avatar float-start bg-light-primary rounded me-1">
                                            <div class="avatar-content">
                                                <i data-feather="map-pin" class="avatar-icon font-medium-3"></i>
                                            </div>
                                        </div>
                                        <div class="more-info">
                                            <h6 class="mb-0">Central Park</h6>
                                            <small>Manhattan, New york City</small>
                                        </div>
                                    </div>
                                    <div class="avatar-group">
                                        <div data-bs-toggle="tooltip" data-popup="tooltip-custom" data-bs-placement="bottom" title="Billy Hopkins" class="avatar pull-up">
                                            <img src="../../assets/images/portrait/small/avatar-s-9.jpg" alt="Avatar" width="33" height="33" />
                                        </div>
                                        <div data-bs-toggle="tooltip" data-popup="tooltip-custom" data-bs-placement="bottom" title="Amy Carson" class="avatar pull-up">
                                            <img src="../../assets/images/portrait/small/avatar-s-6.jpg" alt="Avatar" width="33" height="33" />
                                        </div>
                                        <div data-bs-toggle="tooltip" data-popup="tooltip-custom" data-bs-placement="bottom" title="Brandon Miles" class="avatar pull-up">
                                            <img src="../../assets/images/portrait/small/avatar-s-8.jpg" alt="Avatar" width="33" height="33" />
                                        </div>
                                        <div data-bs-toggle="tooltip" data-popup="tooltip-custom" data-bs-placement="bottom" title="Daisy Weber" class="avatar pull-up">
                                            <img src="../../assets/images/portrait/small/avatar-s-20.jpg" alt="Avatar" width="33" height="33" />
                                        </div>
                                        <div data-bs-toggle="tooltip" data-popup="tooltip-custom" data-bs-placement="bottom" title="Jenny Looper" class="avatar pull-up">
                                            <img src="../../assets/images/portrait/small/avatar-s-20.jpg" alt="Avatar" width="33" height="33" />
                                        </div>
                                        <h6 class="align-self-center cursor-pointer ms-50 mb-0">+42</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--/ Developer Meetup Card -->


                    </div>
                </section>
                <!-- Dashboard Ecommerce ends -->

            </div>
        </div>
    </div>
    <!-- END: Content-->


</template>

<script>
// import the core functions from vuex
import { ref,reactive,inject } from 'vue';
import { useStore } from 'vuex';

import ReportsRecruitmentStatsTiles from '@/components/reports/ReportsRecruitmentStatsTiles.vue';
import ReportsNotificationList from '@/components/reports/ReportsNotificationList.vue';

export default{
    components:{
        ReportsNotificationList,
        ReportsRecruitmentStatsTiles
    },
    setup(){
        // get the global vuex store values
        const store = useStore();

        // get the axios object
        const axios = inject('axios');  // inject axios

        //get the urls
        const coreUrl = inject('coreUrl');


        const existingData = ref(false);
        const reqError = ref("Loading job ad list .... ");

        const keyRecruitmentStats = reactive({
            total:0,
            completed:0,
            ongoing:0,
            drafted:0
        })



            // use axios to get the data
            axios.get(
                coreUrl.backendUrl+'get_job_ad_list',
                {withCredentials: true}).then((response) =>{

                const result = response.data.data;

                existingData.value = true;

                var totalLength = result.length

                keyRecruitmentStats.total = totalLength

                for(var i = 0; i < totalLength; i++){
                    keyRecruitmentStats[result[i]['status']]++
                    // console.log(i)
                    // console.log(result[i]['title'])
                    // console.log(result[i]['status'])
                }



            }).catch((error)=>{
                    let msg = "Could not fetch your job ads at this time."
                    reqError.value = error.response.data.message.req_msg?msg:msg;
                    existingData.value = false;
            })

        const currentDate = new Date()

        const userName = store.getters['auth/getAuthCredentials'].accountName;

        // use the dispatch function to call an action that calls a mutation
        store.dispatch("globals/executeSetNavMenu",true);

        return {
            keyRecruitmentStats,
            currentDate,
            userName
        }
    }
}
</script>

<style scoped>
.date-font{
    font-size:12px;
}
</style>
